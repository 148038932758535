<template>
  <li :id="divId" class="relative" @mouseover="openDropdown">
    <button
      type="button"
      aria-haspopup="true"
      :aria-controls="id"
      :aria-expanded="showMainNavDropdown"
      class="inline-flex items-center gap-x-2 text-lg xl:text-xl font-medium leading-6 text-white hover:text-ol-pink"
      @click="openDropdown"
    >
      {{ title }}
      <i class="caret">
        <ChevronRight
          :class="showMainNavDropdown ? '-rotate-90' : 'rotate-0'"
          class="fill-white transition-transform duration-300 ease-in-out"
        />
      </i>
    </button>
    <MainNavDropdown
      v-show="showMainNavDropdown"
      :id="id"
      :key="id"
      :title="title"
      :content="content"
      @mouseleave="closeDropdown"
    />
  </li>
</template>

<script>
import MainNavDropdown from '@components/MainNav/MainNavDropdown.vue';
import ChevronRight from '@components/Icon/ChevronRight.vue';

export default {
  name: 'MenuItem',
  components: { ChevronRight, MainNavDropdown },

  props: {
    title: String,
    id: Number,
    content: String,
    modelValue: String,
  },

  computed: {
    divId() {
      return 'menuitem-' + this.id;
    },
    showMainNavDropdown() {
      return this.modelValue === this.divId;
    },
  },

  mounted() {
    // Fix for Safari as @blur vue events do not work
    const container = this.$el;

    document.addEventListener(
      'click',
      (e) => {
        if (this.showMainNavDropdown && !container.contains(e.target)) {
          this.closeDropdown();
        }
      },
      false
    );
  },

  methods: {
    openDropdown() {
      this.$emit('update:modelValue', this.divId);
    },
    closeDropdown() {
      this.$emit('update:modelValue', null);
    },
  },
};
</script>
