<template>
  <a
    v-if="linkTo && !disabled"
    :id="id"
    :data-testid="dataTestid"
    :class="[variantClass, size, { saving }]"
    :href="linkTo"
  >
    <slot>Save</slot>
  </a>
  <button
    v-else
    :id="id"
    :data-testid="dataTestid"
    :class="[variantClass, size, { saving }]"
    :disabled="disabled"
    :type="type"
  >
    <slot v-if="variant === 'circle'">
      <Plus />
    </slot>
    <template v-else>
      <OLLoadingSpinner v-if="saving" :key="saving" class="ol-button-loading" />
      <slot>Save</slot>
    </template>
  </button>
</template>

<script setup>
import { computed } from 'vue';
import OLLoadingSpinner from '@components/Animated/OLLoadingSpinner.vue';
import Plus from '@components/Icon/Plus.vue';

const props = defineProps({
  dataTestid: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: null,
  },
  linkTo: {
    type: String,
    default: null,
  },
  saving: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
  },
  /** Flag whether the button is a text button */
  textButton: {
    type: Boolean,
    default: false,
  },
  /** Flag whether the button is a CTA button */
  ctaButton: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
    validator: (value) => ['button', 'submit', 'reset'].includes(value),
  },
  variant: {
    type: String,
    default: 'primary',
    validator: (value) =>
      [
        'hero',
        'hero-inverted',
        'primary',
        'secondary',
        'secondary-white',
        'circle',
        'special',
      ].includes(value),
  },
});

const variantClass = computed(() => {
  if (props.textButton) {
    const textVariant =
      props.textButton && ['primary', 'secondary'].includes(props.variant)
        ? props.variant
        : 'primary';
    return `ol-button${props.ctaButton ? '-cta-' : '-'}text-${textVariant}`;
  }
  return `ol-button${props.ctaButton ? '-cta-' : '-'}${props.variant}`;
});
</script>

<style lang="scss" scoped>
a,
button {
  &.ol-button-hero,
  &.ol-button-hero-inverted,
  &.ol-button-primary,
  &.ol-button-cta-primary,
  &.ol-button-secondary,
  &.ol-button-secondary-white,
  &.ol-button-cta-secondary,
  &.ol-button-circle,
  &.ol-button-cta-circle,
  &.ol-button-special,
  &.ol-button-cta-special,
  &.ol-button-text-primary,
  &.ol-button-cta-text-primary,
  &.ol-button-text-secondary,
  &.ol-button-cta-text-secondary {
    @apply flex
    gap-2
    justify-center items-center
    flex-shrink-0 flex-grow-0
    whitespace-nowrap
    disabled:pointer-events-none
    transition-all;

    &.saving {
      @apply pointer-events-none !text-transparent;
    }
  }

  &.ol-button-hero,
  &.ol-button-hero-inverted,
  &.ol-button-primary,
  &.ol-button-cta-primary,
  &.ol-button-secondary,
  &.ol-button-secondary-white,
  &.ol-button-cta-secondary,
  &.ol-button-circle,
  &.ol-button-cta-circle,
  &.ol-button-special,
  &.ol-button-cta-special {
    @apply rounded-full;
  }

  &.ol-button-hero,
  &.ol-button-hero-inverted,
  &.ol-button-primary,
  &.ol-button-cta-primary,
  &.ol-button-secondary,
  &.ol-button-secondary-white,
  &.ol-button-cta-secondary,
  &.ol-button-special,
  &.ol-button-cta-special {
    @apply w-full md:w-fit;

    &.xs {
      @apply px-4 py-1;
    }

    &.sm {
      @apply px-6 py-3;
    }

    &.md {
      @apply px-8 py-4;
    }

    &.lg {
      @apply px-10 py-5;
    }

    &.xl {
      @apply px-12 py-6;
    }

    > svg.ol-button-loading {
      @apply absolute h-5;
    }
  }

  &.ol-button-primary,
  &.ol-button-cta-primary,
  &.ol-button-cta-circle {
    @apply text-white hover:bg-ol-plum hover:border-ol-plum;

    > svg.ol-button-loading {
      @apply stroke-white;
    }
  }

  &.ol-button-hero-inverted,
  &.ol-button-primary {
    @apply bg-ol-accent-1-100 border-ol-accent-1-100
    disabled:bg-ol-accent-1-20 disabled:border-ol-accent-1-20;
  }

  &.ol-button-cta-primary,
  &.ol-button-cta-circle {
    @apply bg-ol-primary border-ol-primary
    disabled:bg-ol-primary-20 disabled:border-ol-primary-20;
  }

  &.ol-button-hero,
  &.ol-button-hero-inverted,
  &.ol-button-primary,
  &.ol-button-cta-primary,
  &.ol-button-secondary,
  &.ol-button-secondary-white,
  &.ol-button-cta-secondary,
  &.ol-button-special {
    &.xs {
      @apply border;
    }

    &.sm,
    &.md {
      @apply border-2;
    }

    &.lg,
    &.xl {
      @apply border-[3px];
    }
  }

  &.ol-button-secondary,
  &.ol-button-secondary-white {
    @apply hover:border-ol-accent-1-100 hover:bg-ol-accent-1-100;
  }

  &.ol-button-secondary {
    @apply text-ol-plum hover:text-white disabled:text-ol-dark-10
    border-ol-plum disabled:border-ol-dark-10;

    > svg.ol-button-loading {
      @apply stroke-ol-plum;
    }
  }

  &.ol-button-secondary-white {
    @apply text-white disabled:text-ol-dark-10
    border-white disabled:border-ol-dark-10;
  }

  &.ol-button-cta-secondary {
    @apply text-ol-primary disabled:text-ol-primary-20
    border-ol-primary disabled:border-ol-primary-20
    hover:bg-ol-primary-10;

    > svg.ol-button-loading {
      @apply stroke-ol-primary;
    }
  }

  &.ol-button-hero,
  &.ol-button-circle,
  &.ol-button-special,
  &.ol-button-cta-special {
    @apply text-white
    bg-ol-plum border-ol-plum
    disabled:bg-ol-dark-20 disabled:border-ol-dark-20;

    > svg.ol-button-loading {
      @apply stroke-white;
    }
  }

  &.ol-button-hero,
  &.ol-button-special {
    @apply hover:bg-ol-accent-1-100 hover:border-ol-accent-1-100;
  }

  &.ol-button-hero {
    @apply lg:bg-white lg:border-white lg:text-black lg:hover:text-white;
  }

  &.ol-button-hero-inverted {
    @apply text-white hover:bg-white hover:border-white hover:text-black;
  }

  &.ol-button-cta-special {
    @apply hover:bg-ol-primary hover:border-ol-primary;
  }

  &.ol-button-circle,
  &.ol-button-cta-circle {
    @apply aspect-1;

    &.xs {
      @apply p-1;
    }

    &.sm {
      @apply p-3;
    }

    &.md {
      @apply p-4;
    }

    &.lg {
      @apply p-5;
    }

    &.xl {
      @apply p-6;
    }
  }

  &.ol-button-text-primary,
  &.ol-button-cta-text-primary,
  &.ol-button-text-secondary,
  &.ol-button-cta-text-secondary {
    @apply hover:underline disabled:no-underline;
  }

  &.ol-button-text-primary {
    @apply text-ol-accent-1-100 disabled:text-ol-accent-1-20;
  }

  &.ol-button-cta-text-primary {
    @apply text-ol-primary disabled:text-ol-primary-20;
  }

  &.ol-button-text-secondary,
  &.ol-button-cta-text-secondary {
    @apply text-ol-plum disabled:text-ol-dark-10;
  }

  &.xs {
    @apply text-xs;
  }

  &.sm {
    @apply text-sm;
  }

  &.lg {
    @apply text-lg;
  }

  &.xl {
    @apply text-xl;
  }
}
</style>
