<template>
  <div class="w-full mt-6 grid grid-cols-5 items-center justify-center">
    <Link
      :href="href"
      class="button--label rounded-ol-cta py-4 px-5 font-ol-roobert text-[20px] leading-[20px] col-span-5 lg:col-start-2 lg:col-span-3"
      :class="[classBackground]"
    >
      <slot />
    </Link>
  </div>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    fill: {
      type: String,
      default: 'pink',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classBackground() {
      if (this.disabled) {
        return 'bg-transparent border-2 border-gray-50 text-gray-50';
      } else if (this.fill === 'transparent') {
        return 'bg-transparent border border-2 border-ol-pink text-ol-pink';
      } else {
        return 'bg-ol-pink text-ol-black';
      }
    },
  },
};
</script>

<style scoped></style>
