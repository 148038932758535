<template>
  <section class="where-to-start">
    <Group class="mx-auto" />
    <header class="where-to-start__header">Not sure where to start?</header>
    <p class="lg:text-xs">Take the 1 min quiz to help you.</p>

    <ButtonRounded
      variant="transparent"
      width="full"
      :size="buttonSize"
      class="mx-auto"
      @click="takeQuiz"
    >
      <div class="where-to-start__quiz">
        <span class="my-auto">Take the quiz</span>
        <Arrow class="w-3 my-auto" />
      </div>
    </ButtonRounded>
  </section>
</template>

<script>
import MainNavDropdownTitle from './MainNavDropdownTitle.vue';
import Group from '../../Icon/Group.vue';
import ButtonRounded from '../../Button/ButtonRounded.vue';
import ButtonLink from '../../../bow/components/ButtonLink.vue';
import Arrow from '../../Icon/Arrow.vue';
import { computed } from 'vue';
import { useScreenSize } from '../../../composables/useScreenSizeHelper';
import { ScreenSizes } from '../../../enums';

export default {
  components: {
    ButtonLink,
    ButtonRounded,
    MainNavDropdownTitle,
    Group,
    Arrow,
  },

  setup() {
    let { screenWidth } = useScreenSize();

    const buttonSize = computed(() => {
      return screenWidth.value >= ScreenSizes.lg ? 'xs' : 'small';
    });

    const takeQuiz = () => {
      window.location.href = route('bow');
    };

    return { buttonSize, takeQuiz, screenWidth };
  },
};
</script>

<style>
.where-to-start {
  @apply text-ol-dark-ink-200 flex justify-between gap-6 flex-col bg-ol-secondary-10  py-10 px-8 transition duration-150 ease-in-out;
}

.where-to-start__header {
  @apply font-medium text-lg lg:text-sm;
}

.where-to-start__quiz {
  @apply flex text-center justify-center gap-1;
}
</style>
