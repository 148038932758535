<template>
  <li class="flex w-108">
    <MainNavDropdownList class="item w-2/3">
      <MainNavDropdownTitle>{{ navItems.title }}</MainNavDropdownTitle>
      <MainNavDropdownListLink
        v-for="item in navItems.items"
        :key="item.name"
        :href="item.route"
      >
        <div class="flex gap-4">
          <keep-alive>
            <component
              :is="item.icon"
              class="nav-menu__icon"
            />
          </keep-alive>
          <header class="text-base font-bold text-nowrap">
            {{ item.name }}
          </header>
        </div>
      </MainNavDropdownListLink>
    </MainNavDropdownList>
    <DropdownNotSureWhereToStart />
  </li>
</template>

<script setup>
import MainNavDropdownList from '../partials/MainNavDropdownList.vue';
import MainNavDropdownListLink from '../partials/MainNavDropdownListLink.vue';
import MainNavDropdownTitle from '../partials/MainNavDropdownTitle.vue';
import DropdownNotSureWhereToStart from '../partials/DropdownNotSureWhereToStart.vue';
import {
  SparklesIcon,
  ChatBubbleLeftEllipsisIcon,
  BriefcaseIcon,
  PhoneIcon,
} from '@heroicons/vue/24/outline';

const navItems = {
  title: 'About us',
  items: [
    {
      name: 'Octopus Legacy',
      icon: SparklesIcon,
      route: route('about'),
    }, {
      name: 'Guides',
      icon: ChatBubbleLeftEllipsisIcon,
      route: route('advice.index'),
    },
    {
      name: 'Careers',
      icon: BriefcaseIcon,
      route: `${route('careers')}`,
    }, {
      name: 'Contact Us',
      icon: PhoneIcon,
      route: route('contact'),
    },
  ],
};

</script>

<style lang="scss" scoped>
.nav-menu__icon {
  @apply my-auto h-6 flex-shrink-0 flex-grow-0;
}
</style>

