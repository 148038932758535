import { ref } from 'vue';

export function useDisablePageScroll() {
  /** Calculate the scrollbar width to ensure correct padding when disabling scrolling */
  const scrollbarWidth = ref(
    window.innerWidth - document.documentElement.clientWidth
  );

  /** Disable scrolling on the page (& prevent layout shift) */
  const disablePageScroll = () => {
    document.body.classList.add('overflow-hidden');
    if (scrollbarWidth.value) {
      document.body.style.paddingRight = `${scrollbarWidth.value + 12}px`;
    }
  };

  /** Enable scrolling on the page (& prevent layout shift) */
  const enablePageScroll = () => {
    document.body.classList.remove('overflow-hidden');
    document.body.style.paddingRight = null;
  };

  /** Set the scrollbarWidth using `window innerwidth - documentElement.clientWidth` */
  const setScrollbarWidth = () => {
    scrollbarWidth.value =
      window.innerWidth - document.documentElement.clientWidth;
  };

  return { enablePageScroll, disablePageScroll, setScrollbarWidth };
}
