<template>
  <div class="nav overscroll-contain">
    <div v-if="modelValue === null">
      <ul class="nav__items">
        <li
          v-for="(menu, index) in menus"
          :key="index"
          class="nav__items__item"
        >
          <button class="flex" @click="() => $emit('update:modelValue', index)">
            {{ menu.title }}
            <ChevronRight class="nav__chevron" />
          </button>
        </li>
      </ul>
      <DropdownNotSureWhereToStart class="nav__where-to-start" />
    </div>
    <transition>
      <MobileMenuItem
        v-if="modelValue !== null"
        :nav-items="menus[modelValue]"
      />
    </transition>
    <div class="nav__button-group">
      <MainNavCTA />
      <OLButton
        v-if="!isAuthenticated"
        variant="secondary"
        :link-to="route('login')"
      >
        Sign in
      </OLButton>
    </div>
    <MainNavAccount v-if="isAuthenticated" class="mt-6" />
  </div>
</template>

<script setup>
import ChevronRight from '../Icon/ChevronRight.vue';
import DropdownNotSureWhereToStart from './partials/DropdownNotSureWhereToStart.vue';
import MainNavAccount from './MainNavAccount.vue';
import MainNavCTA from './partials/MainNavCTA.vue';
import MobileMenuItem from './MobileMenuItem.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  BriefcaseIcon,
  ChatBubbleLeftEllipsisIcon,
  DocumentTextIcon,
  GiftIcon,
  HeartIcon,
  PencilIcon,
  PhoneIcon,
  ScaleIcon,
  ShieldCheckIcon,
  SparklesIcon,
} from '@heroicons/vue/24/outline';
import OLButton from '@components/Button/OLButton.vue';

defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
});

const willRoute = computed(() => {
  return window.guardianAngel?.user?.hasWill
    ? route('will-basic.onboarding-welcome')
    : route('will-writing');
});

const menus = ref([
  {
    title: 'About us',
    itemsClass: 'font-medium',
    items: [
      {
        name: 'Octopus Legacy',
        icon: SparklesIcon,
        route: route('about'),
      },
      {
        name: 'Guides',
        icon: ChatBubbleLeftEllipsisIcon,
        route: route('advice.index'),
      },
      {
        name: 'Careers',
        icon: BriefcaseIcon,
        route: `${route('careers')}`,
      },
      {
        name: 'Contact Us',
        icon: PhoneIcon,
        route: route('contact'),
      },
    ],
  },
  {
    title: 'Plan for Death',
    itemsClass: 'font-bold',
    items: [
      {
        name: 'Will Writing',
        description: 'Write a will that’s tailored to you',
        icon: PencilIcon,
        route: willRoute.value,
      },
      {
        name: 'Life Insurance',
        description: 'Life Insurance for the 21st century',
        icon: ShieldCheckIcon,
        route: route('life-insurance'),
      },
      {
        name: 'Lasting Power of Attorney',
        description:
          'Appoint someone you trust to make \n' + 'decisions on your behalf',
        icon: DocumentTextIcon,
        route: route('lasting-power-of-attorney'),
      },
      {
        name: 'Legacy Box',
        description: 'Make life easier for the people you leave behind',
        icon: GiftIcon,
        route: route('fwig'),
      },
    ],
  },
  {
    title: 'Get support after loss',
    itemsClass: 'font-bold',
    items: [
      {
        name: 'Bereavement Support Hubs',
        description: 'Build a supportive community',
        icon: HeartIcon,
        route: route('bereavement'),
      },
      {
        name: 'Probate',
        description: 'Your guide through probate',
        icon: ScaleIcon,
        route: route('probate'),
      },
      {
        name: 'Dedicated Concierge',
        description: 'Personal support after loss',
        icon: PhoneIcon,
        route: route('death-concierge'),
      },
    ],
  },
]);

const store = useStore();
const isAuthenticated = store.getters['user/isAuthenticated'];
</script>

<style scoped>
.nav__button-group {
  @apply p-10 flex flex-col sm:flex-row sm:px-10 bg-white gap-2;
}

.nav {
  height: calc(100vh - 4rem);
  @apply bg-white w-full flex flex-col justify-between;
}

.nav__items {
  @apply p-5 flex flex-col gap-8;
}

.nav__items__item {
  @apply text-ol-dark-ink-200 text-2xl cursor-pointer flex  py-2;
}

.nav__where-to-start {
  @apply w-full sm:m-10 text-center sm:w-72 sm:h-72;
}

.nav__chevron {
  @apply my-auto fill-ol-dark-ink-200;
}
</style>
